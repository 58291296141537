import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styles from "../components/styles/languageToggler.module.css"

const LanguageToggler = () => {
  const [actualPath, setActualPath] = useState("") //El path de la pagina actual
  useEffect(() => {
    setActualPath(window.location.pathname)
  }, [])

  let language = "english"

  const toggler = () => {
    if (actualPath.includes("/en/")) {
      language = "español"
      return actualPath.replace("/en/", "/")
    } else {
      return actualPath.replace("/", "/en/")
    }
  }

  return (
    <>
      <div className={styles.lgToggler}>
        <Link to={toggler()}>{language}</Link>
      </div>
    </>
  )
}

export default LanguageToggler
