import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import LanguageToggler from "../components/languageToggler"
import styles from "../components/styles/header.module.css"

const Header = ({ siteTitle }) => {
  useEffect(() => {
    window.onscroll = () =>
      setScrolled(window.scrollY / document.documentElement.scrollHeight)
  }, [])
  // valor de cuanto se scrolleo
  const [scrolled, setScrolled] = useState(0)

  // controlador de la className (se asignna la classname dark a const dark cuando
  // se escrollea mas de 0.25)
  const [dark, setDark] = useState(0)

  if (scrolled > 0.04 && dark === 0) {
    setDark(styles.dark)
  }
  // se resetea la const dark para que el header vuelva a ser naranaja
  if (scrolled < 0.04 && dark !== 0) {
    setDark(0)
  }

  // Multi language funcionality
  const [path, setPath] = useState("") //El path de la pagina actual
  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  let disenoYdesarrllo = "proyectos"
  let infoContacto = "info + contacto"
  let homePath = "/"
  let infoId = "/#info"

  if (path.includes("/en/")) {
    disenoYdesarrllo = "projects"
    infoContacto = "info + contact"
    homePath = "/en/"
    infoId = "/en/#info"
  }

  return (
    <header className={dark}>
      <div className={styles.headerContainer}>
        <div>
          <Link className={styles.homepageLink} to={homePath}>
            Lucas Boh.
          </Link>
        </div>
        <nav className={styles.nav}>
          <Link className={styles.navItem} to="/desarrollo-diseno-web/">
            {disenoYdesarrllo}
          </Link>
          <a className={styles.navItem} href={infoId}>
            {infoContacto}
          </a>
        </nav>
        <LanguageToggler />
        <h1>{siteTitle}</h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
