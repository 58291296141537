import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "typeface-noto-serif"
import "typeface-inter"
import "./styles/layout.css"
import styles from "./styles/layout.module.css"
import { Helmet } from "react-helmet";

const Layout = ({ children, bgColor, siteTitle, noFooter }) => {
  let footerColor = "#1A202C"
  if (bgColor === "#0D0D0D") {
    footerColor = "#F7FAFC"
  }

  return (
    <>
      <Helmet>
        {/* Google Analytics tracking code */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-PX3R42XM85"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-PX3R42XM85');
        `}
        </script>
      </Helmet>
      {siteTitle && <Header siteTitle={siteTitle} />}
      <div style={{ backgroundColor: bgColor }}>
        <div className={styles.mainFooterCont}>
          <main>{children}</main>
          {!noFooter && (
            <footer
              style={{ marginTop: 90, color: footerColor, fontWeight: "bold" }}
            >
              © {new Date().getFullYear()} lucasboh.com
              {` `}
            </footer>
          )}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
